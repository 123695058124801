import Container from 'components/container'
import Layout from 'components/layout'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import ArticleBody from 'components/organisms/articleBody'
import {
  ArticleGridItem,
  ArticlesGrid,
} from 'components/organisms/articlesList'
import FeatureImage from 'components/organisms/featureImage'
import Features from 'components/organisms/features'
import Hero from 'components/organisms/hero'
import Instagram from 'components/organisms/instagram'
import { graphql, PageProps } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import { useResourceMediaData } from 'hooks/useResourceMediaData'
import { useResourceTextData } from 'hooks/useResourceTextData'
import * as React from 'react'
import {
  ContentfulHeroPanelConnection,
  ContentfulJobConnection,
  ContentfulResourceTextConnection,
  Site,
} from 'types/graphql-types'

type IndexTypes = PageProps & {
  data: {
    site: Site
    allContentfulHeroPanel: ContentfulHeroPanelConnection
    allContentfulJob: ContentfulJobConnection
    allContentfulResourceSet: ContentfulResourceTextConnection
  }
}

const RootIndex = ({ data, ...props }: IndexTypes): JSX.Element => {
  const heroData = getLocalNodes(data.allContentfulHeroPanel.nodes)
  const jobData = getLocalNodes(data.allContentfulJob.nodes)
  const featuresData = getLocalNodes(data.allContentfulResourceSet.nodes)

  return (
    <Layout header="trans">
      <Seo
        title="Careers"
        url={props.location.href}
        imageUrl={heroData[0].image.fluid.src.replace(/^\/\//, 'https://')}
      />
      <Hero {...heroData[0]} image={heroData[0].image.fluid} />

      <Container>
        <ArticleBody type="standalone">
          <div className="row">
            <div className="content">
              <p className="lead">
                {useResourceTextData(
                  'careers.leadingParagraph',
                  `We’re all about building an open environment that allows us to
                  excite and inspire each other and those who we connect with
                  every day of the week – this is how we manage to keep our
                  business energetic, fresh and passionate after more than 25
                  years.`
                )}
              </p>
              <p>
                {useResourceTextData(
                  'careers.body',
                  `As espresso specialists, we like to take a planned and
                  considered approach to all that we do, ensuring every aspect of
                  our brand encompasses our values. We’re a growing coffee
                  business, spanning across the globe – from Christchurch to
                  Brisbane, London to Tokyo. In all our local and global
                  communities, we are united in our focus on caring about each
                  other, our customers and believing in what we do. We need great
                  people who will bring their ideas, talent and most importantly,
                  their passion to our business. Are you in?`
                )}
              </p>
            </div>
            <div />
          </div>
        </ArticleBody>

        <ArticlesGrid>
          {jobData.map((job, i) => (
            <ArticleGridItem key={job.id} uniform>
              <Card
                title={job.title}
                slug={`/careers/${job.slug}`}
                subTitle={{
                  title: job.location,
                }}
                image={job.heroImage}
                i={i}
              />
            </ArticleGridItem>
          ))}
        </ArticlesGrid>

        <FeatureImage
          title={useResourceTextData(
            'careers.featureTitle',
            'What’s on offer?'
          )}
          image={useResourceMediaData('careers.featureImage')}
        />

        {featuresData[0] && <Features items={featuresData[0].resources} />}
      </Container>
      <Instagram />
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query CareersQuery {
    allContentfulHeroPanel(filter: { key: { eq: "careers.hero" } }) {
      nodes {
        id
        subTitle
        title
        image {
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        body {
          body
        }
        cta {
          ... on ContentfulLink {
            ...Link
          }
          ... on ContentfulRegionalLink {
            ...RegionalLink
          }
        }
        node_locale
      }
    }
    allContentfulJob {
      nodes {
        id
        title
        slug
        location
        node_locale
        heroImage {
          fluid {
            ...Image
          }
        }
      }
    }
    allContentfulResourceSet(filter: { name: { eq: "Careers features" } }) {
      nodes {
        resources {
          ... on ContentfulFeature {
            id
            image {
              fluid {
                ...Image
              }
            }
            title
            body
          }
        }
        node_locale
      }
    }
  }
`
