import Link from 'components/atoms/link'
import { PlaceholderImage } from 'components/atoms/placeholderImage'
import Share from 'components/organisms/share'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import ChevDown from 'images/svg/chev-down.svg'
import * as React from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { ContentfulCafeFilterTag } from 'types/graphql-types'

const StyledCard = styled.div<{ size: string; hero: boolean }>`
  ${({ theme, size, hero }): CSSProp => css`
    a {
      text-decoration: none;
    }
    .image {
      margin-bottom: 2rem;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        margin-bottom: 1.5rem;
      }
    }
    .sub-title {
      color: ${theme.colours.tertiary};
      text-transform: uppercase;
      font-weight: normal;
      font-size: ${theme.font.fontSizeSmall};
      letter-spacing: 1px;
      margin: 0 0 1.5rem;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        font-size: 1rem;
      }
      svg {
        transform: rotate(90deg);
        height: 0.5rem;
        margin-right: 0.6rem;
        position: relative;
        top: -1px;
        vertical-align: middle;
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          display: none;
        }
      }
    }
    .title {
      font-size: ${theme.font.fontSizeBodyLarge};
      line-height: 1;
      margin: 0;
      @media only screen and ${theme.breakpoints.toNormalScreen} {
        font-size: ${theme.font.fontSizeLarge};
      }
    }
    .children {
      margin-top: 2rem;
    }
    .tags {
      margin: 1.5rem 0 0;
      padding: 0;
      list-style: none;
      li {
        display: inline;
        margin-right: 1.5rem;
      }
      a {
        font-size: ${theme.font.fontSizeSmall};
        text-decoration: underline;
      }
    }
    .share {
      text-align: right;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        display: none;
      }
    }
    ${size === 'lg' &&
      css`
        @media only screen and ${theme.breakpoints.fromNormalScreen} {
          display: flex;
          .placeholder-wrapper {
            flex-basis: 55%;
          }
          .image {
            flex: 0 0 31.8%;
            margin: 0;
          }
          .content {
            padding: 5rem 4.5rem;
          }
          .title {
            font-weight: ${theme.font.fontWeightLight};
          }
        }
        .title {
          line-height: 1.45;
          font-size: ${theme.font.fontSizeBodyLarge};
        }
      `}

    ${hero &&
      css`
        .image {
          flex-basis: 55%;
          position: relative;
          z-index: 1;
          @media only screen and ${theme.breakpoints.toNormalScreen} {
            margin-left: -2rem;
            margin-right: -2rem;
          }
          img {
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          font-family: ${theme.font.fontFamilyAlt};
          font-size: 2.2rem;
          line-height: 1.5;
          font-weight: ${theme.font.fontWeightBold};
          @media only screen and ${theme.breakpoints.fromNormalScreen} {
            font-size: 2.8rem;
            line-height: 1.3;
          }
          @media only screen and ${theme.breakpoints.fromLargeScreen} {
            font-size: 3.8rem;
          }
        }
        .content {
          padding: 1rem 0 0;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media only screen and ${theme.breakpoints.fromNormalScreen} {
            padding: 5.5rem;
            min-height: 28rem;
            &:after {
              content: '';
              position: absolute;
              top: 100%;
              left: -100vw;
              background: ${theme.colours.light};
              width: 200vw;
              height: 500px;
            }
          }
          @media only screen and ${theme.breakpoints.fromLargeScreen} {
            padding: 7rem;
            min-height: 38rem;
          }
        }
      `}
  `}
`

type CardProps = {
  size?: 'lg'
  title: string
  slug?: string
  subTitle?: { title: string; slug?: string }
  tags?: ContentfulCafeFilterTag[]
  image?: { fluid?: FluidObject }
  hero?: boolean
  share?: boolean
  i?: number
  children?: React.ReactNode
}

const Card = ({
  size,
  title,
  slug,
  subTitle,
  tags = null,
  image,
  hero,
  share,
  i,
  children,
}: CardProps): JSX.Element => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "placeholder" } }) {
          nodes {
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 800) {
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    `
  )

  return (
    <StyledCard className="card" size={size} hero={hero}>
      {image && (
        <div className="image">
          {hero ? (
            <Img fluid={{ ...image.fluid, aspectRatio: 1.5 }} />
          ) : (
            <Link to={slug}>
              <Img fluid={{ ...image.fluid, aspectRatio: 1.333 }} />
            </Link>
          )}
        </div>
      )}
      {!image && !hero && (
        <div className="image">
          {!isNaN(i) ? (
            <Link to={slug}>
              <Img
                fluid={{
                  ...allFile.nodes[i % allFile.nodes.length].childImageSharp
                    .fluid,
                  aspectRatio: 1.333,
                }}
              />
            </Link>
          ) : (
            <Link to={slug} className="placeholder-wrapper">
              <PlaceholderImage />
            </Link>
          )}
        </div>
      )}
      <div>
        <div className="content">
          {share && <Share />}
          {subTitle && (
            <h4 className="sub-title">
              {subTitle.slug ? (
                <Link to={subTitle.slug}>
                  {hero && <ChevDown />}
                  {subTitle.title}
                </Link>
              ) : (
                <>{subTitle.title}</>
              )}
            </h4>
          )}
          {hero ? (
            <>
              <h1 className="title">{title}</h1>
              {children && <div className="children">{children}</div>}
            </>
          ) : (
            <Link to={slug} className="title">
              {title}
            </Link>
          )}

          {tags?.length > 0 && (
            <ul className="tags">
              {tags.map((item, i) => (
                <li key={i}>
                  <Link
                    to={
                      item.internal?.type === 'ContentfulCafeFilterTag'
                        ? `/find/?tag=${item.slug}`
                        : `/community/tag/${item.slug}`
                    }
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </StyledCard>
  )
}

export default Card
